@media only screen and (min-width: 1240px) {
  .dashboard .container {
    width: 1240px !important;
    margin: auto;
  }

  .dashboard .lottie-container {
    height: 25rem;
    width: 25rem;
  }
}

@media only screen and (max-width: 480px) {
  .dashboard .lottie-container {
    height: 15rem;
    width: 15rem;
  }
}

@media only screen and (max-width: 768) {
  .dashboard .lottie-container {
    height: 25rem;
    width: 25rem;
  }
}

.dashboard {
    height: calc(100% - 32px - 2rem);
    overflow: auto;
    
    .container {
        height: 100%;
        width: 100%;
        section#github-section {
            justify-content: space-between;
            height: 100%;
            .title-container {
                height: calc(100% - 20rem);
                display: flex;
                justify-content: center;
                .title-item {
                  h1 {
                    font-size: 2rem;
                  }
                  .lottie-container {
                    margin: auto;
                  }
                  align-self: center;
                  text-align: center;
                }
            }
            .github-list-container {
                width: 100%;
                height: 20rem;
                .tab-container {
                  margin: auto;
                  height: 3rem;
                  .tabs {
                    margin-left: 4rem;
                    display: flex;
                    width: auto;
                    .tab {
                      margin-right: 1rem;
                      cursor: pointer;
                      &:hover {
                        color: rgb(255, 245, 154);
                      }
                    }
                  }
                  .selected {
                    text-decoration: underline;
                    color: rgb(255, 251, 212);
                  }
                }
                .github-list-window {
                    margin: auto;
                    height: calc(100% - 3.5rem);
                    width: 100%;
                }
            }
        }
    }
}
  
